<template>
  <div>
    <div class="box">
      <div>
        <form @submit.prevent="onSubmit"></form>
        <div class="columns">
          <div class="column is-one-quarter">
            <b-field label="Usuario">
              <b-input v-model="user"/>
            </b-field>
          </div>
          <div class="column is-one-quarter">
            <b-field label="Contraseña">
              <b-input v-model="password" type="password"/>
            </b-field>
          </div>
          <div class="column is-1">
            <p class="control">
              <button class="button  is-primary" type="submit" @click="onSubmit">
                Actualizar
              </button>
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "UserPassword",
  data(){
    return {
      user: '',
      password: ''
    }
  },
  methods: {
    onSubmit(){
      this.$loader.show();
      this.$http.post('/admin/user-password', {
        username: this.user,
        password: this.password
      }).then((response) => {
        this.$toast.success(response.data.message);
      }).catch(errors => {
        this.$alfalab.showError(errors);
      }).finally(() => {
        this.user = '';
        this.password = '';
        this.$loader.hide();
      })
    }
  }
}
</script>

<style scoped>

</style>
